import React, { useState } from "react"
import { graphql } from "gatsby"
import AnimateHeight from "react-animate-height"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Form from "../components/form"

// import MountainIMG from "../assets/images/mont_blanc/mb3.jpg"
import { ChevronRight } from "react-bootstrap-icons"
import DynamicImage from "../utils/dynamicimage"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query {
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    prismicNewsletter {
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        subscribe_text {
          text
        }
      }
    }
    allPrismicNewsletterIssue(
      sort: { order: DESC, fields: data___publishing_date }
    ) {
      edges {
        node {
          uid
          id
          data {
            issue_title {
              text
            }
            excerpt {
              text
            }
            publishing_date(formatString: "DD.MM.YYYY")
            issue_link {
              url
            }
            issue_image {
              alt
              url
              fluid(maxWidth: 900) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

function Newsletter({ data }) {
  const [height, setHeight] = useState(0)
  const [showArchive, toggleArchive] = useState(false)

  const { subscribe_text } = data.prismicNewsletter.data

  function toggleArchiveState() {
    toggleArchive(!showArchive)
    setHeight(height === 0 ? "auto" : 0)
  }

  const allIssuesArray = data.allPrismicNewsletterIssue.edges.map(
    ({ node }) => {
      return (
        <a
          key={node.id}
          href={node.data.issue_link && node.data.issue_link.url}
          target="_blank"
          rel="noreferrer"
          className="group"
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="150"
          // data-sal-easing="ease"
        >
          {/* <p className="text-sm text-cb-500 sm:text-base lg:text-xl">{"01".toUpperCase()}</p> */}
          <div className="grid grid-cols-6 col-span-1 row-span-5 gap-2 px-0 py-0 pb-6 transition-colors duration-500 bg-white rounded-md shadow-lg lg:px-8 lg:py-8 grid-auto-rows lg:grid-rows-3 lg:gap-10 group-hover:bg-gray-100">
            <div className="w-full h-48 col-start-1 col-end-7 row-start-1 row-end-1 overflow-hidden lg:col-start-1 lg:col-end-4 lg:row-start-1 lg:row-end-4 rounded-t-md lg:rounded-t-none md:h-56 lg:h-full lg:overflow-visible">
              <DynamicImage
                className="min-w-full min-h-full"
                placeholderClassName="w-full object-cover object-center"
                style={{ maxHeight: "100%" }}
                imgStyle={{
                  objectFit: "cover",
                  width: "100%",
                  objectPosition: "50% 50%",
                  maxHeight: "100%",
                }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={node.data.issue_image?.alt}
                src={node.data.issue_image && node.data.issue_image.url}
                fluid={
                  node.data.issue_image &&
                  node.data.issue_image.fluid &&
                  node.data.issue_image.fluid
                }
              />
            </div>
            <div className="flex flex-col h-full max-w-md col-start-1 col-end-7 row-start-2 row-end-2 px-3 pt-0 sm:pt-2 lg:pt-0 lg:px-0 md:px-6 lg:col-start-4 lg:col-end-7 lg:row-start-1 lg:row-end-4 min-w-md">
              <h2 className="text-2xl font-medium leading-tight sm:leading-tight lg:leading-tight lg:text-3xl">
                {node.data.issue_title && node.data.issue_title.text}
              </h2>
              <p className="text-sm font-thin text-gray-500 sm:text-base lg:text-base lg:pb-1">
                {node.data.publishing_date}
              </p>
              <p className="mt-0 text-sm font-light text-gray-800 sm:text-base lg:text-lg">
                {node.data.excerpt && node.data.excerpt.text}
              </p>
            </div>
          </div>
        </a>
      )
    }
  )

  return (
    <Layout data={data}>
      <div id="main-container min-h-screen" className="bg-mbSnow">
        <SEO
          title={data.prismicNewsletter.data.seo_title?.text}
          description={data.prismicNewsletter.data.seo_description?.text}
        />
        <div
          className="flex flex-col min-h-screen section-1"
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <div className="container flex flex-col items-center min-h-full col-start-2 col-end-12 row-start-1 row-end-1 pt-2 intro lg:pt-12">
            <div className="px-2">
              <h1 className="pt-8 text-4xl leading-tight text-center sm:leading-tight lg:leading-tight sm:text-5xl lg:text-5xl lg:pt-0 lg:pb-3">
                Newsletter
              </h1>
              <p className="max-w-lg px-3 text-sm font-light text-justify lg:text-center sm:text-base lg:text-lg">
                {subscribe_text.text}
              </p>
            </div>
            <Form autoFocus="autoFocus" />
          </div>
          <div className="flex flex-col justify-end flex-grow flex-shrink-0 min-h-full mt-10">
            <div className="flex flex-col items-center justify-center w-full py-3 sm:py-10 bg-bs-300">
              <button
                className="relative flex items-center justify-center flex-grow-0 text-4xl focus:outline-none group max-w-max"
                onClick={toggleArchiveState}
                aria-label="toggle newsletter archive"
              >
                <ChevronRight
                  className={
                    "absolute left-0 -m-8 w-5 h-5 lg:w-6 lg:h-6 mr-3 transition duration-500 focus:outline-none group-hover:text-cb-500 webkit-fix" +
                    (showArchive ? " transform rotate-90" : "")
                  }
                  size={56}
                  strokeWidth={0.5}
                  // data-sal="fade"
                  // data-sal-duration="2000"
                  // data-sal-delay="150"
                  // data-sal-easing="ease"
                />
                <h2 className="text-xl sm:text-2xl lg:text-3xl xl:text-4xl focus:outline-none">
                  Archive
                </h2>
              </button>
            </div>
          </div>
        </div>
        <AnimateHeight
          id="newsletter-archive"
          duration={500}
          height={height}
          className={"col-start-1 col-end-13 row-start-2 bg-bs-300 4xl:px-64"}
          contentClassName="container px-2 sm:px-5 pt-3 pb-20 w-full grid grid-cols-1 gap-5 gap-y-16"
        >
          {allIssuesArray}
        </AnimateHeight>
        {/* <Footer /> */}
      </div>
    </Layout>
  )
}

export default withPreview(Newsletter)
